import React, { useState, useEffect } from "react";
import DataService from "../services/api/data_services";

import Layout from "../components/layouts/layout";
import Typography from "@material-ui/core/Typography";
import Infoview from "../templates/InfoView";

import BackArrowIcon from "../assets/Images/accordian.svg";
import Button from "../components/Button";
//Image Banner
import ImageBanner from "../templates/ImageBanner";
import BannerBgImage from "../assets/Images/WhatWeDo/what-we-do-banner-image.png";
//Mini Image Banner
// import MiniImageBanner from "../templates/MiniImageBanner";
import MiniImgBnrBgImg from "../assets/Images/HowWeDoIt/contactus_250px.png";

//Text Image Quotes Card View
// import TextImageQuotesCardView from "../templates/TextImageQuotesCardView";
import clutchLogo from "../assets/Images/WhatWeDo/clutch-co-vector-logo.svg";

//Mini Image Banner1
// import MiniImageBanner1 from "../templates/MiniImageBanner1";
import MiniImageBannerBgImg from "../assets/Images/WhatWeDo/who_we_work_with.png";
import MiniImageBannerBgImg12 from "../assets/Images/HowWeDoIt/who_we_work_with.png";

import { BASE_SITE_URL } from "../shared/constants";
//Contact info
import TwoColumnContactInfo from "../templates/TwoColumnContactInfo";
import Clm1Img from "../assets/Icons/phone.svg";
import Clm2Img from "../assets/Icons/mail.svg";
import ogimage from "../assets/Images/ogImages/OG_What-we-do.png";

import DigirytePods from "../assets/Images/Homepage/AgileServices/digirytePods.png";
import StaffAug from "../assets/Images/Homepage/AgileServices/StaffAug.png";
import StaffAughover from "../assets/Images/Homepage/AgileServices/StaffAughover.png";
import productdeve from "../assets/Images/Homepage/AgileServices/productdeve.png";
import DigirytepodsHover from "../assets/Images/Homepage/DigirytepodsHover.png";
import ProductdeveHover from "../assets/Images/Homepage/AgileServices/ProductdeveHover.png";
import PlainCardWithHover from "../components/PlainCardWithHover";

import LocationCards from "../templates/OurLocation";
import mapImage from "../assets/Images/Homepage/Locations/location.png";
import Banglore from "../assets/Images/Homepage/Locations/Banglore.png";
import Chennai from "../assets/Images/Homepage/Locations/Chennai.png";
import Coimbatore from "../assets/Images/Homepage/Locations/Coimbatore.png";
import Hyderabad from "../assets/Images/Homepage/Locations/Hyderabad.png";
import Mumbai from "../assets/Images/Homepage/Locations/Mumbai.png";
import UK from "../assets/Images/Homepage/Locations/UK.png";

import mapImage1 from "../assets/Images/mobileView/Our_Locations/OurLocation.png";
import mChennai from "../assets/Images/mobileView/Our_Locations/Chennai.png";
import mBangalore from "../assets/Images/mobileView/Our_Locations/bangalore.png";
import mCoimbatore from "../assets/Images/mobileView/Our_Locations/Coimbatore.png";
import mHyderabad from "../assets/Images/mobileView/Our_Locations/Hyderabad.png";
import Manchester from "../assets/Images/mobileView/Our_Locations/Manchester.png";
import mMumbai from "../assets/Images/mobileView/Our_Locations/Mumbai.png";

import BlurCardList from "../templates/BlurCardList";
import MiniCardBgImage1 from "../assets/Images/Homepage/mobile_application_development.png";
import MiniCardBgImage2 from "../assets/Images/Homepage/web_application_development.png";
import MiniCardBgImage3 from "../assets/Images/Homepage/full_product_development.png";
import MiniCardBgImage4 from "../assets/Images/Homepage/MVP.png";
import MiniCardBgImage5 from "../assets/Images/Homepage/Product_designing_and_Prototyping.png";
import MiniCardBgImage6 from "../assets/Images/Homepage/product_scaling.png";
import MiniCardBgImage7 from "../assets/Images/Homepage/code_ux_audit.png";
import MiniCardBgImage8 from "../assets/Images/Homepage/digital_transformation.png";
import MiniCardBgImage9 from "../assets/Images/Homepage/machine_learning.png";
import OurServicesCard from "../components/OurServicesCard";
import RevealAnimation from "../components/RevealAnimation";

const WhatWeDo = () => {
  const [clientStoryMbl, setClientStoryMbl] = useState([]);
  const [clientStoryWeb, setClientStoryWeb] = useState([]);
  const [clientStoryFull, setClientStoryFull] = useState([]);
  const [clientStoryMVP, setClientStoryMVP] = useState([]);
  const [clientStoryProduct, setClientStoryProduct] = useState([]);
  const [clientStoryScaling, setClientStoryScaling] = useState([]);
  const [clientStoryCode, setClientStoryCode] = useState([]);
  const [clientStoryDigital, setClientStoryDigital] = useState([]);
  const [clientStoryMachine, setClientStoryMachine] = useState([]);

  useEffect(() => {
    DataService.getData(
      `/admin/api/what-we-do?page=whatwedo&service_type=Mobile Application Development&type=Client Story&component=mini_view`
    ).then((response) => {
      setClientStoryMbl([...clientStoryMbl, ...response]);
    });
    DataService.getData(
      "/admin/api/what-we-do?page=whatwedo&service_type=Web Application Development&type=Client Story&component=mini_view"
    ).then((response) => {
      setClientStoryWeb([...clientStoryWeb, ...response]);
    });
    DataService.getData(
      `/admin/api/what-we-do?page=whatwedo&service_type=Full Product Development&type=Client Story&component=mini_view`
    ).then((response) => {
      setClientStoryFull([...clientStoryFull, ...response]);
    });
    DataService.getData(
      `/admin/api/what-we-do?page=whatwedo&service_type=MVP Development&type=Client Story&component=mini_view`
    ).then((response) => {
      setClientStoryMVP([...clientStoryMVP, ...response]);
    });
    DataService.getData(
      `/admin/api/what-we-do?page=whatwedo&service_type=Product Designing&component&type=Client Story&component=mini_view`
    ).then((response) => {
      setClientStoryProduct([...clientStoryProduct, ...response]);
    });
    DataService.getData(
      `/admin/api/what-we-do?page=whatwedo&service_type=Product Scaling&type=Client Story&component=mini_view`
    ).then((response) => {
      setClientStoryScaling([...clientStoryScaling, ...response]);
    });
    DataService.getData(
      `/admin/api/what-we-do?page=whatwedo&service_type=Code %26 UI/UX Audit&type=Client Story&component=mini_view`
    ).then((response) => {
      setClientStoryCode([...clientStoryCode, ...response]);
    });
    DataService.getData(
      `/admin/api/what-we-do?page=whatwedo&service_type=Digital Transformation&type=Client Story&component=mini_view`
    ).then((response) => {
      setClientStoryDigital([...clientStoryDigital, ...response]);
    });
    DataService.getData(
      `/admin/api/what-we-do?page=whatwedo&service_type=Machine Learning&type=Client Story&component=mini_view`
    ).then((response) => {
      setClientStoryMachine([...clientStoryMachine, ...response]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * TextImageQuotesCardView
   */
  const TextImageQuotesCardViewData = [
    {
      heading: {
        heading: (
          <>
            <span className="red-line3letter red-3letter-mbl">MOB</span>ILE
            APPLICATION DEVELOPMENT
          </>
        ),
        width: "56px",
        cardNo: "odd",
        content1:
          "Our expertise in building robust, multi-platform mobile applications ensures a consistent user experience for iOS, Android or Windows devices and platforms. We’ve got the right team to build the right solution for your business.",
      },
      card1: {
        title: "KEY BENEFITS TO YOU",
        content: (
          <div>
            <b>Mobile App Experience:</b> Our Mobile App experience ensures your
            app is delivered as agreed, on time, in budget.
            <br /> <b>Security:</b> High priority, robust security and testing
            processes prevents costly security breaches in your Mobile App.
            <br />
            <b> Compliance:</b> Our Apple/Google App Store expertise ensures
            your Mobile App is built fast and accepted first time.
          </div>
        ),
        subTitle: <div style={{ color: "#db4435" }}>If you need to:</div>,
        subContent:
          "Create next-generation mobile applications to increase user engagement and upscale your business",
        label: "Learn More",
        customClassName: "effect-btn1 no-padding-left mt-20",
        route: "/mobile-application-development",
      },
      card2: {
        image: clientStoryMbl.length > 0 ? clientStoryMbl[0].og_image : "",
        imageAlt:
          clientStoryMbl.length > 0 ? clientStoryMbl[0].imageAltTxt : "",
        title: clientStoryMbl.length > 0 ? clientStoryMbl[0].meta_title : "",
        heading: "EXPLORE",
        content:
          clientStoryMbl.length > 0 &&
          clientStoryMbl[0].meta_description !== null
            ? clientStoryMbl[0].meta_description
            : "",
        type: clientStoryMbl.length > 0 ? clientStoryMbl[0].slug_type : "",
        slug: clientStoryMbl.length > 0 ? clientStoryMbl[0].slug : "",
        Type: clientStoryMbl.length > 0 ? clientStoryMbl[0].type : "",
        footerLabel: "CASE STUDY",
      },
      card3: {
        title:
          "We can’t speak highly enough of Digiryte’s team because they bent over backward to help us launch our product",
        italicContent: "Mobile App Dev for Automative Sales App",
        content: "Founder, Bumper UK",
        footerContent: (
          <div
            style={{ display: "flex", fontSize: "10px", alignItems: "center" }}
          >
            <div>Authentic client review from</div>
            <div style={{ paddingLeft: "5px", fontSize: "12px" }}>
              <img src={clutchLogo} alt="Clutch" height="50px" />
            </div>
          </div>
        ),
      },
    },
    {
      heading: {
        heading: (
          <>
            <span className="red-line3letter red-3letter-mbl">WEB</span>{" "}
            APPLICATION DEVELOPMENT
          </>
        ),
        cardNo: "even",
        width: "52px",
        content1:
          "We create custom web applications that are designed to meet business challenges, promote growth and achieve your business goals. We use adaptive web applications and keep the end-users in mind to simplify complex business workflows.",
      },
      card1: {
        title: "KEY BENEFITS TO YOU",
        content: (
          <div>
            <b>Any App Stage:</b> Convenient range of services covering all app
            stages - research, launch, growth or maturity.
            <br />
            <b>Quality: </b>Highly trained staff, ISO 9001 standards and use of
            best practices guarantees a high-quality Web App. <br />
            <b>Protect:</b> Coding and other best practices ensure your App can
            be extended without extensive re-working.
          </div>
        ),
        subTitle: <div style={{ color: "#db4435" }}>If you need to:</div>,
        subContent:
          "Build an advanced web application to support various aspects of your business functions",
        label: "Learn More",
        customClassName: "effect-btn1 no-padding-left mt-20",
        route: "/web-application-development",
      },
      card2: {
        image: clientStoryWeb.length > 0 ? clientStoryWeb[0].og_image : "",
        imageAlt:
          clientStoryWeb.length > 0 ? clientStoryWeb[0].imageAltTxt : "",
        title: clientStoryWeb.length > 0 ? clientStoryWeb[0].title : "",
        heading: "EXPLORE",
        content:
          clientStoryWeb.length > 0 &&
          clientStoryWeb[0].meta_description !== null
            ? clientStoryWeb[0].meta_description
            : "",
        type: clientStoryWeb.length > 0 ? clientStoryWeb[0].slug_type : "",
        slug: clientStoryWeb.length > 0 ? clientStoryWeb[0].slug : "",
        Type: clientStoryWeb.length > 0 ? clientStoryWeb[0].type : "",
        footerLabel: "CASE STUDY",
      },
      card3: {
        title: "Digiryte was on time and has competitive prices.",
        italicContent: "Web application Design for Car Dealership",
        content: "Director, Chanscars",
        footerContent: (
          <div
            style={{ display: "flex", fontSize: "10px", alignItems: "center" }}
          >
            <div>Authentic client review from</div>
            <div style={{ paddingLeft: "5px", fontSize: "12px" }}>
              <img src={clutchLogo} alt="Clutch" height="50px" />
            </div>
          </div>
        ),
      },
    },
    {
      heading: {
        heading: (
          <>
            <span className="red-line3letter red-3letter-mbl">FULL</span>{" "}
            PRODUCT DEVELOPMENT
          </>
        ),
        cardNo: "odd",
        width: "45px",
        content1:
          "We provide quick ideation, prototyping and MVP development to ensure your product meets the needs of the market. We can help you scale and expand your business to a new level at every stage of your product development cycle.",
      },
      card1: {
        title: "KEY BENEFITS TO YOU",
        content: (
          <div>
            <b>Convenience:</b> Design, MVP, Build, Scale. With experts in all
            areas, select all services or just the ones you need.
            <br /> <b>Audit Trail:</b> Technical, Project and Requirements
            documents maintained so you have a complete audit trail. <br />
            <b>One-stop-shop:</b> Save time dealing with multiple companies.
            Access to all related services in one place.
          </div>
        ),
        subTitle: <div style={{ color: "#db4435" }}>If you need to:</div>,
        subContent:
          "Create a next-generation mobile application to increase user engagement and upscale your business",
        label: "Learn More",
        customClassName: "effect-btn1 no-padding-left mt-20",
        route: "/full-product-development",
      },
      card2: {
        image: clientStoryFull.length > 0 ? clientStoryFull[0].og_image : "",
        imageAlt:
          clientStoryFull.length > 0 ? clientStoryFull[0].imageAltTxt : "",
        title: clientStoryFull.length > 0 ? clientStoryFull[0].meta_title : "",
        heading: "EXPLORE",
        content:
          clientStoryFull.length > 0 &&
          clientStoryFull[0].meta_description !== null
            ? clientStoryFull[0].meta_description
            : "",
        type: clientStoryFull.length > 0 ? clientStoryFull[0].slug_type : "",
        slug: clientStoryFull.length > 0 ? clientStoryFull[0].slug : "",
        Type: clientStoryFull.length > 0 ? clientStoryFull[0].type : "",
        footerLabel: "CASE STUDY",
      },
      card3: {
        title: "If I could describe them in one word, it’d be cooperative.",
        italicContent: "Flutter Development for Home Services Marketplace App",
        content: "Founder, Home Services Marketplace",
        footerContent: (
          <div
            style={{ display: "flex", fontSize: "10px", alignItems: "center" }}
          >
            <div>Authentic client review from</div>
            <div style={{ paddingLeft: "5px", fontSize: "12px" }}>
              <img src={clutchLogo} alt="Clutch" height="50px" />
            </div>
          </div>
        ),
      },
    },
  ];

  /**
   * TextImageQuotesCardView
   */
  const TextImageQuotesCardViewData1 = [
    {
      heading: {
        heading: (
          <>
            <span className="red-line3letter red-3letter-mbl">MVP</span>{" "}
            DEVELOPMENT
          </>
        ),
        cardNo: "odd",
        width: "47px",
        content1:
          "Before you move on to create a new digital product, you should definitely consider launching a Minimum Viable Product (MVP) to assess your business risk. Use MVP design to lower the cost of market testing and use real-time feed back to improve your product.",
      },
      card1: {
        title: "KEY BENEFITS TO YOU",
        content: (
          <div>
            <b>Short Cuts:</b> Refine and speed up MVP design by utilising our
            expert Business, Growth & Innovation team. <br />
            <b>Reduce Risk:</b> Analyse your business hypotheses. Build MVP to
            validate the riskiest elements. Stop wasted effort.
            <br /> <b>Validation:</b> Identify KPIs that will validate your idea
            using your MVP. Learn and refine to get the perfect App.
          </div>
        ),
        subTitle: <div style={{ color: "#db4435" }}>If you need to:</div>,
        subContent:
          "Quickly test your business hypothesis for the new app you want to build for your product or services.",
        label: "Learn More",
        customClassName: "effect-btn1 no-padding-left mt-20",
        route: "/mvp-development",
      },
      card2: {
        image: clientStoryMVP.length > 0 ? clientStoryMVP[0].og_image : "",
        imageAlt:
          clientStoryMVP.length > 0 ? clientStoryMVP[0].imageAltTxt : "",
        title: clientStoryMVP.length > 0 ? clientStoryMVP[0].meta_title : "",
        heading: "EXPLORE",
        content:
          clientStoryMVP.length > 0 &&
          clientStoryMVP[0].meta_description !== null
            ? clientStoryMVP[0].meta_description
            : "",
        type: clientStoryMVP.length > 0 ? clientStoryMVP[0].slug_type : "",
        slug: clientStoryMVP.length > 0 ? clientStoryMVP[0].slug : "",
        Type: clientStoryMVP.length > 0 ? clientStoryMVP[0].type : "",
        footerLabel: "CASE STUDY",
      },
      card3: {
        title:
          "They promised a functioning Web application skeleton but delivered something even better.",
        italicContent: "AI Dating Software Dev for Production Company",
        content: (
          <div>
            Former Series Producer,
            <br /> Production Company
          </div>
        ),
        footerContent: (
          <div
            style={{ display: "flex", fontSize: "10px", alignItems: "center" }}
          >
            <div>Authentic client review from</div>
            <div style={{ paddingLeft: "5px", fontSize: "12px" }}>
              <img src={clutchLogo} alt="Clutch" height="50px" />
            </div>
          </div>
        ),
      },
    },
    {
      heading: {
        heading: (
          <>
            <span className="red-line3letter red-3letter-mbl">PRO</span>DUCT
            DESIGINING AND PROTOTYPING
          </>
        ),
        cardNo: "even",
        width: "50px",
        content1:
          "Good product design creates a visual language that drives user engagement, making your product easier to market. We design and develop innovative app prototypes, to help demonstrate the product-market fit.",
      },
      card1: {
        title: "KEY BENEFITS TO YOU",
        content: (
          <div>
            <b>Results focus:</b> Our UX/UI Designers are trained to deliver
            visuals that are engaging and intuitive.
            <br />
            <b>Flexible Pricing Models:</b> Choose a pricing model to suit your
            needs. Time & Material, Fixed Cost, or Hybrid.
            <br />
            <b>Collaboration:</b> Our platform enables 2-way feedback for speedy
            designs that you can clearly visualize.
          </div>
        ),
        subTitle: <div style={{ color: "#db4435" }}>If you need to:</div>,
        subContent:
          "Create a product with user-friendly UI/UX to present to your potential investors.",
        label: "Learn More",
        customClassName: "effect-btn1 no-padding-left mt-20",
        route: "/product-designing-and-prototyping",
      },
      card2: {
        image:
          clientStoryProduct.length > 0 ? clientStoryProduct[0].og_image : "",
        imageAlt:
          clientStoryProduct.length > 0
            ? clientStoryProduct[0].imageAltTxt
            : "",
        title:
          clientStoryProduct.length > 0 ? clientStoryProduct[0].meta_title : "",
        heading: "EXPLORE",
        content:
          clientStoryProduct.length > 0 &&
          clientStoryProduct[0].meta_description !== null
            ? clientStoryProduct[0].meta_description
            : "",
        type:
          clientStoryProduct.length > 0 ? clientStoryProduct[0].slug_type : "",
        slug: clientStoryProduct.length > 0 ? clientStoryProduct[0].slug : "",
        Type: clientStoryProduct.length > 0 ? clientStoryProduct[0].type : "",
        footerLabel: "CASE STUDY",
      },
      card3: {
        title:
          "Digiryte walks us through the process from an educational perspective in a way that really makes us feel cared about.",
        italicContent: "Interactive Web & App Dev for Business Dev Company",
        content: "CEO, Direct Opportunities Network",
        footerContent: (
          <div
            style={{ display: "flex", fontSize: "10px", alignItems: "center" }}
          >
            <div>Authentic client review from</div>
            <div style={{ paddingLeft: "5px", fontSize: "12px" }}>
              <img src={clutchLogo} alt="Clutch" height="50px" />
            </div>
          </div>
        ),
      },
    },
    {
      heading: {
        heading: (
          <>
            <span className="red-line3letter red-3letter-mbl">PRO</span>DUCT
            SCALING
          </>
        ),
        cardNo: "odd",
        width: "50px",
        content1:
          "In order to scale up your business, you should not only respond to user feedback and suggestions but also anticipate user needs in order to increase market share. Scaling involves a number of small but significant changes that are added to the product feature in order to thrive in an ever-evolving market.",
      },
      card1: {
        title: "KEY BENEFITS TO YOU",
        content: (
          <div>
            <b>Agility:</b> Scale quickly. Access to teams with diverse,
            multiple skill sets coupled with robust recruitment processes.
            <br />
            <b>Data Security:</b> With ISO 27001 accreditation, you can be
            assured we manage all your information in a secure manner.
            <br /> <b>Technology Experts:</b> Our team know the factors that
            affect product scaling but still deliver you a quality product.
          </div>
        ),
        subTitle: <div style={{ color: "#db4435" }}>If you need to:</div>,
        subContent:
          "Add new features and upgrade your existing app to capture new markets.",
        label: "Learn More",
        customClassName: "effect-btn1 no-padding-left mt-20",
        route: "/product-scaling",
      },
      card2: {
        image:
          clientStoryScaling.length > 0 ? clientStoryScaling[0].og_image : "",
        imageAlt:
          clientStoryScaling.length > 0
            ? clientStoryScaling[0].imageAltTxt
            : "",
        title:
          clientStoryScaling.length > 0 ? clientStoryScaling[0].meta_title : "",
        heading: "EXPLORE",
        content:
          clientStoryScaling.length > 0 &&
          clientStoryScaling[0].meta_description !== null
            ? clientStoryScaling[0].meta_description
            : "",
        type:
          clientStoryScaling.length > 0 ? clientStoryScaling[0].slug_type : "",
        slug: clientStoryScaling.length > 0 ? clientStoryScaling[0].slug : "",
        Type: clientStoryScaling.length > 0 ? clientStoryScaling[0].type : "",
        footerLabel: "CASE STUDY",
      },
      card3: {
        title:
          "They are easy to reach, supportive, and quick at responding and solving any issues.",
        italicContent: "Web application Dev for Northern Neonatal Network",
        content: (
          <div>
            Clinical Lead, Northern <br /> Neonatal Network
          </div>
        ),
        footerContent: (
          <div
            style={{ display: "flex", fontSize: "10px", alignItems: "center" }}
          >
            <div>Authentic client review from</div>
            <div style={{ paddingLeft: "5px", fontSize: "12px" }}>
              <img src={clutchLogo} alt="Clutch" height="50px" />
            </div>
          </div>
        ),
      },
    },
  ];

  /**
   * TextImageQuotesCardView
   */
  const TextImageQuotesCardViewData2 = [
    {
      heading: {
        heading: (
          <>
            <span className="red-line3letter red-3letter-mbl">COD</span>E & UX
            AUDIT
          </>
        ),
        cardNo: "odd",
        width: "51px",
        content1:
          "Identify security flaws by auditing your software’s source code and UX. This step helps you avoid any technical problems and keep your digital product in good condition.",
      },
      card1: {
        title: "KEY BENEFITS TO YOU",
        content: (
          <div>
            <b>Project Rescue:</b> App Project in distress? We’ll audit it and
            recommend accordingly so it gets you back on track.
            <br /> <b>Due diligence:</b> Buying a business? We’ll audit any
            software to identify flaws and save you costly re-writes.
            <br />
            <b>Specification:</b> Use the audit to build a specification, RFI or
            RFP. Identify your requirements and get consistent proposals.
          </div>
        ),
        subTitle: <div style={{ color: "#db4435" }}>If you need to:</div>,
        subContent:
          "Conduct a customised audit for your web or mobile application’s code and UI/UX.",
        label: "Learn More",
        customClassName: "effect-btn1 no-padding-left mt-20",
        route: "/code-ux-audit",
      },
      card2: {
        image: clientStoryCode.length > 0 ? clientStoryCode[0].og_image : "",
        imageAlt:
          clientStoryCode.length > 0 ? clientStoryCode[0].imageAltTxt : "",
        title: clientStoryCode.length > 0 ? clientStoryCode[0].meta_title : "",
        heading: "EXPLORE",
        content:
          clientStoryCode.length > 0 &&
          clientStoryCode[0].meta_description !== null
            ? clientStoryCode[0].meta_description
            : "",
        type: clientStoryCode.length > 0 ? clientStoryCode[0].slug_type : "",
        slug: clientStoryCode.length > 0 ? clientStoryCode[0].slug : "",
        Type: clientStoryCode.length > 0 ? clientStoryCode[0].type : "",
        footerLabel: "CASE STUDY",
      },
      card3: {
        title:
          "I can’t fault them. Everything was done efficiently, quickly, and politely.",
        italicContent: "Web application Redesign for Prenatal Care Company",
        content: (
          <div>
            Director, Professional <br /> Antenatal Services
          </div>
        ),
        footerContent: (
          <div
            style={{ display: "flex", fontSize: "10px", alignItems: "center" }}
          >
            <div>Authentic client review from</div>
            <div style={{ paddingLeft: "5px", fontSize: "12px" }}>
              <img src={clutchLogo} alt="Clutch" height="50px" />
            </div>
          </div>
        ),
      },
    },
    {
      heading: {
        heading: (
          <>
            <span className="red-line3letter red-3letter-mbl">DIG</span>ITAL
            TRANSFORMATION
          </>
        ),
        cardNo: "even",
        width: "40px",
        content1:
          "Transform your traditional business model and give it a new digital identity to adapt to changing circumstances. At Digiryte, we help you with your digital transition to build a product for the future.",
      },
      card1: {
        title: "KEY BENEFITS TO YOU",
        content: (
          <div>
            <b>Experience:</b> Trust us to deliver your Digital Transformation
            project. We’ve done it extensively for many others!!
            <br /> <b>Legacy Systems: </b> We audit Legacy Systems and include
            new needs to ensure they are transformed efficiently.
            <br />
            <b>Satisfy Stakeholders:</b> Our services help you satisfy the needs
            of all your team - Management, Finance, IT etc
          </div>
        ),
        subTitle: <div style={{ color: "#db4435" }}>If you need to:</div>,
        subContent:
          "Take your business to the digital world and future proof it, with advanced digital solutions.",
        label: "Learn More",
        customClassName: "effect-btn1 no-padding-left mt-20",
        route: "/digital-transformation",
      },
      card2: {
        image:
          clientStoryDigital.length > 0 ? clientStoryDigital[0].og_image : "",
        imageAlt:
          clientStoryDigital.length > 0
            ? clientStoryDigital[0].imageAltTxt
            : "",
        title:
          clientStoryDigital.length > 0 ? clientStoryDigital[0].meta_title : "",
        heading: "EXPLORE",
        content:
          clientStoryDigital.length > 0 &&
          clientStoryDigital[0].meta_description !== null
            ? clientStoryDigital[0].meta_description
            : "",
        type:
          clientStoryDigital.length > 0 ? clientStoryDigital[0].slug_type : "",
        slug: clientStoryDigital.length > 0 ? clientStoryDigital[0].slug : "",
        Type: clientStoryDigital.length > 0 ? clientStoryDigital[0].type : "",
        footerLabel: "CASE STUDY",
      },
      card3: {
        title:
          "Digiryte’s expertise with the technology helped speed up the process.",
        italicContent:
          "Web application Development for Healthcare Management Company",
        content: "Founder, MedzDigital",
        footerContent: (
          <div
            style={{ display: "flex", fontSize: "10px", alignItems: "center" }}
          >
            <div>Authentic client review from</div>
            <div style={{ paddingLeft: "5px", fontSize: "12px" }}>
              <img src={clutchLogo} alt="Clutch" height="50px" />
            </div>
          </div>
        ),
      },
    },
    {
      heading: {
        heading: (
          <>
            <span className="red-line3letter red-3letter-mbl">MAC</span>HINE
            LEARNING
          </>
        ),
        cardNo: "odd",
        width: "52px",
        content1:
          "With advanced analytics services, make insight-led decisions for your business. If your business is sitting on a mountain of data, let us help you unlock it’s true potential for your business.",
      },
      card1: {
        title: "KEY BENEFITS TO YOU",
        content: (
          <div>
            <b>Subject Expertise:</b> Our Machine Learning experts know what’s
            needed. Use them, Learn, Save time and effort. <br />
            <b>Robust Testing:</b> Function, Network, Speed testing and more.
            Our process means your App performs as required. <br />
            <b>Best Practice:</b> Using best practices in Machine Learning, you
            can be sure it can scale and expand efficiently.
          </div>
        ),
        subTitle: <div style={{ color: "#db4435" }}>If you need to:</div>,
        subContent:
          "Improve your decision-making skills by analysing and using captured data to your advantage.",
        label: "Learn More",
        customClassName: "effect-btn1 no-padding-left mt-20",
        route: "/machine-learning",
      },
      card2: {
        image:
          clientStoryMachine.length > 0 ? clientStoryMachine[0].og_image : "",
        imageAlt:
          clientStoryMachine.length > 0
            ? clientStoryMachine[0].imageAltTxt
            : "",
        title:
          clientStoryMachine.length > 0 ? clientStoryMachine[0].meta_title : "",
        heading: "EXPLORE",
        content:
          clientStoryMachine.length > 0 &&
          clientStoryMachine[0].meta_description !== null
            ? clientStoryMachine[0].meta_description
            : "",
        type:
          clientStoryMachine.length > 0 ? clientStoryMachine[0].slug_type : "",
        slug: clientStoryMachine.length > 0 ? clientStoryMachine[0].slug : "",
        Type: clientStoryMachine.length > 0 ? clientStoryMachine[0].type : "",
        footerLabel: "CASE STUDY",
      },
      card3: {
        title:
          "They promised a functioning website skeleton but delivered something even better.",
        italicContent: "Web Dev & Automation for E-liquid Subscription Service",
        content: (
          <div>
            Former Series Producer, <br /> Production Company{" "}
          </div>
        ),
        footerContent: (
          <div
            style={{ display: "flex", fontSize: "10px", alignItems: "center" }}
          >
            <div>Authentic client review from</div>
            <div style={{ paddingLeft: "5px", fontSize: "12px" }}>
              <img src={clutchLogo} alt="Clutch" height="50px" />
            </div>
          </div>
        ),
      },
    },
  ];
  const MiniImageBanner1Data1 = {
    title: (
      <span>
        Hire Remote <br /> <span className="black-line3letter">Tea</span>ms
      </span>
    ),
    titleClass: "mini-image-banner-title-area3 min-title-header",
    image: MiniImageBannerBgImg,
    altTxt: "Hire Remote Teams",
    btnClassName: "image-banner-resize-btn",
    btnLabel: "TAKE A LOOK",
    btnVariant: "contained",
    btnColor: "primary",
    route: "/hire-remote-teams",
    underline: false,
    tooltip: "yes",
    tip: "TAKE A LOOK",
    underlineClass: "black-line",
  };

  const MiniImageBanner3Data = {
    Id: "minibanner1",
    MiniImageBannerBgImg: MiniImgBnrBgImg,
    MiniImageBannerAltTxt: "Contact Us to Estimate Your Product",
    HoverLinkContent: "Contact Us",
    NearHoverLinkContent: " To ",
    PlainContent: "Estimate Your Product",
    route: "/contact-us",
  };
  /**
   * Mini Image Banner 1
   */

  const MiniImageBanner1Data = {
    title: (
      <>
        Who we work <br /> with
      </>
    ),
    titleClass: "mini-image-banner-title-area3 title-header2",
    image: MiniImageBannerBgImg12,
    tag: "h2",
    altTxt: "Who we work with",
    btnClassName: "takelook-image-banner-resize-btn",
    btnLabel: "TAKE A LOOK",
    tip: "TAKE A LOOK",
    tooltip: "yes",
    btnVariant: "contained",
    btnColor: "primary",
    route: "/who-we-work-with",
    underline: true,
    underlineClass: "black-line",
  };
  /**
   * Infoview
   */

  const InfoviewData = {
    color: "primary",
    width: "101px",
    InfoviewTitle: (
      <>
        {" "}
        <span className="red-line3letter red-3letter-mbl red-3letter-mbl">
          Our
        </span>{" "}
        Engagement Models
      </>
    ),
    InfoviewContent: (
      <>
        We only hire the top 3% of the premium talent in India. We also have all
        of our account management and client facing senior teams available at
        arms length in the UK. This gives us the unique ability to create a
        balance between great communication and great service. Our high
        performing teams consisting of world-class capabilities with agile
        consulting, business analysis, modern application development,
        continuous delivery, quality assurance, business intelligence are
        available for you to leverage. All you need to do is give us a call.
      </>
    ),
  };
  /**
   * Image Banner
   */
  const ImageBannerData = {
    bnrImg: BannerBgImage,
    bnrImgTitle: <>What we do</>,
    bnrImgSubTitle: "How can we help you today?",
    servicesColumn1: {
      data: [
        {
          service: "Team Augmentation",
          link: "/team-augmentation",
        },
      ],
    },
    servicesColumn2: {
      data: [
        {
          service: "Digiryte Pods",
          link: "/digiryte-pods",
        },
      ],
    },
    servicesColumn3: {
      data: [
        {
          service: "Product Development",
          link: "/product-development",
        },
      ],
    },
  };
  const imageData = {
    image1: StaffAug,
    imgHover1: StaffAughover,
    route1: "/team-augmentation",

    image2: DigirytePods,
    imgHover2: DigirytepodsHover,
    route2: "/digiryte-pods",

    image3: productdeve,
    imgHover3: ProductdeveHover,
    route3: "/product-development",
  };
  const LocationCardData = {
    mapImage: mapImage,
    locationImages: [
      {
        image: UK,
        imgTitle: (
          <>
            Manchester <br /> (HQ)
          </>
        ),
        route: "/locations/manchestor",
      },
      {
        image: Banglore,
        imgTitle: "Bangalore",
        route: "/locations/bangalore",
      },
      {
        image: Hyderabad,
        imgTitle: "Hyderabad",
        route: "/locations/hyderabad",
      },
      {
        image: Mumbai,
        imgTitle: "Mumbai",
        route: "/locations/mumbai",
      },
      {
        image: Chennai,
        imgTitle: "Chennai",
        route: "/locations/chennai",
      },
      {
        image: Coimbatore,
        imgTitle: "Coimbatore",
        route: "/locations/coimbatore",
      },
    ],
  };
  const LocationCardData1 = {
    mapImage: mapImage1,
    locationImages: [
      {
        image: Manchester,
        imgTitle: (
          <>
            Manchester <br /> (HQ)
          </>
        ),
        route: "/locations/manchestor",
      },
      {
        image: mBangalore,
        imgTitle: "Bangalore",
        route: "/locations/bangalore",
      },
      {
        image: mHyderabad,
        imgTitle: "Hyderabad",
        route: "/locations/hyderabad",
      },
      {
        image: mMumbai,
        imgTitle: "Mumbai",
        route: "/locations/mumbai",
      },
      {
        image: mChennai,
        imgTitle: "Chennai",
        route: "/locations/chennai",
      },
      {
        image: mCoimbatore,
        imgTitle: "Coimbatore",
        route: "/locations/coimbatore",
      },
    ],
  };
  const BlurCardListData = {
    cardsData: [
      {
        image: MiniCardBgImage4,
        title: "MVP Development",
        subTitle:
          "Build a basic model to demonstrate the core functionality of your product, test it with early adopters and fulfil the primary goal of your business.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/mvp-development",
      },
      {
        image: MiniCardBgImage6,
        title: "Data Engineering",
        subTitle:
          "Create robust data pipelines and advanced analytics solutions, optimising processes, generating insights, and fostering innovation across your organisation",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/data-engineering",
      },
      {
        image: MiniCardBgImage9,
        title: "AI / ML",
        subTitle:
          "Through AI / ML, we can help you make informed decisions to improve business scalability and operational efficiency.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/machine-learning",
      },
      {
        image: MiniCardBgImage6,
        title: "Product Scaling",
        subTitle:
          "Found your product-market fit? Great! Let us help you with a profitable scaling process that provides both sustainability and stability for your product.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/product-scaling",
      },
      {
        image: MiniCardBgImage1,
        title: "Mobile Application Development",
        subTitle:
          "Take business to your customers by building advanced native and hybrid mobile apps for both Android and iOS Platforms.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/mobile-application-development",
      },
      {
        image: MiniCardBgImage2,
        title: "Web Application Development",
        subTitle:
          "Accelerate different functions of your business by creating custom web applications using the latest in digital technologies.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/web-application-development",
      },

      {
        image: MiniCardBgImage3,
        title: "Full Product Development",
        subTitle:
          "The process of full product development encompasses all steps needed to take your product from concept to market place.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/full-product-development",
      },
      {
        image: MiniCardBgImage5,
        title: "Product Designing and Prototyping",
        subTitle:
          "Create a prototype of your product to visualise your idea and generate feedback without incurring the building costs of a fully functioning system.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/product-designing-and-prototyping",
      },

      {
        image: MiniCardBgImage7,
        title: "Code & UX Audit",
        subTitle:
          "With a regular audit, we can help you fix your product, improve its performance, gain new users, and boost your brand reputation in the marketplace.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/code-ux-audit",
      },
      {
        image: MiniCardBgImage8,
        title: "Digital Transformation",
        subTitle:
          "Update your traditional business processes and customer experience to meet the ever-changing market requirements with the help of next-generation digital technologies.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/digital-transformation",
      },
    ],
  };
  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  const OurLocation = {
    // route: "/what-we-do",
    // color: "primary",
    // variant: "outlined",
    // tooltip: "yes",
    // width: "11%",
    // InfoviewButtonLabel: "LEARN MORE",
    InfoviewTitle: (
      <div className="ttc">
        {" "}
        <span className="red-line3letter red-3letter-mbl">Our</span> Locations
      </div>
    ),
    InfoviewContent: (
      <>
        All our senior management team, tech consultants, client directors, and
        account managers are based in the UK. Our development team, consisting
        of the top 3% of premium professionals, is made up of British and Indian
        developers. We ensure that all project data is stored securely within UK
        borders, with access to the development team provided strictly on an
        as-needed basis.
      </>
    ),
  };

  return (
    <Layout
      seo={{
        title: "What We Do | Digiryte",
        ogtitle: "What We Do | Digiryte",
        description:
          "At Digiryte, we help you create digital products from simple customizations to full-cycle software development, ensuring your business's success in the digital age.",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}/what-we-do`,
        keywords:
          "digital products, customizations, software development, full-cycle development, Digiryte services",
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />
      <div className="mt-50 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="Home"
          routepath="/"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          Home
        </Typography>
      </div>
      <div className="mt-30">
        <Infoview InfoviewData={InfoviewData} />
      </div>
      {/* <PlainCardWithHover imageData={imageData} /> */}
      <OurServicesCard />
      {/* <div className="mt-20">
        <MiniImageBanner1
          id="two"
          MiniImageBanner1Data={MiniImageBanner1Data}
        />
      </div> */}
      <div className="body-container mt-70 ttc" id="our-expertise">
        <h2 className="title-header">
          <span className="red-line3letter red-3letter-mbl">Our</span> Expertise
        </h2>
      </div>
      <div className="mt-70">
        <BlurCardList componentId="1" BlurCardListData={BlurCardListData} />
      </div>
      <div className="common-bg-light mt-70">
        <RevealAnimation animation="zoom-in" className="">
          <Infoview InfoviewData={OurLocation} />
        </RevealAnimation>
        <div className="hide-in-mobile">
          <LocationCards
            mapImage={mapImage}
            label="View Location"
            LocationCardData={LocationCardData}
          />
        </div>
        <div className="hide-in-web">
          <LocationCards
            mapImage={mapImage1}
            label="View Location"
            LocationCardData={LocationCardData1}
          />
        </div>
      </div>

      <div>
        <TwoColumnContactInfo
          TwoColumnContactInfoData={TwoColumnContactInfoData}
        />
      </div>
    </Layout>
  );
};

export default WhatWeDo;
